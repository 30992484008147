import React from "react";
import PortableText from "@sanity/block-content-to-react";

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    image: props => {
      return <img src={props.node.asset.url} alt={props.node.alt} />;
    },
  },
};

interface Blocks {
  blocks: any[];
}

const ContentBlock: React.FC<Blocks> = ({ blocks }) => {
  return (
    <PortableText
      blocks={blocks}
      serializers={serializers}
      projectId={process.env.GATSBY_SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_SANITY_DATASET}
    />
  );
};

export default ContentBlock;
