import { Query } from "@graphql-types";
import { graphql, PageProps } from "gatsby";
import React from "react";
import SEO from "../components/shared/Seo";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { ArticleTitle, BackLink } from "@util/standard";
import ArticleSection from "../components/shared/sub/section";
import DonateBlock from "../components/shared/DonateBlock";

const EventWrapper = styled.div`
  width: 100%;
  margin: 200px auto 100px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 150px auto 80px auto;
  }
`;

interface Props extends PageProps {
  data: Query;
}

const EventsTemplate = (props: Props) => {
  const event = props.data.allSanityEvents.nodes[0];
  if (event == null) return null;
  return (
    <>
      <SEO seoData={event.seo} slug={event?.slug?.current} />
      <EventWrapper>
        <BackLink href="/events">{`<`} Back</BackLink>
        <ArticleTitle fontWeight="bold">{event.title}</ArticleTitle>
        {event.contentSections &&
          event.contentSections.map(section => {
            if (section == null) return;
            return (
              <ArticleSection
                sectionData={section}
                imageAlignRight={section.imageAlignRight}
              />
            );
          })}
      </EventWrapper>
      <DonateBlock />
    </>
  );
};

export default EventsTemplate;

export const query = graphql`
  query eventsTemplateQuery($slug: String) {
    allSanityEvents(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        seo {
          ...sanitySeo
        }
        shortDescription
        title
        slug {
          current
        }
        thumbnailImage {
          asset {
            fluid(maxWidth: 800) {
              srcSetWebp
              srcSet
            }
          }
        }
        eventDate(formatString: "do MMMM, YYYY | h:mma")
        contentSections {
          ...sanityArticleContentSection
        }
      }
    }
  }
`;
