import { SanityArticleContentSection } from "@graphql-types";
import BlockContent from "@sanity/block-content-to-react";
import { ImageWrapper, SectionImg, TextWrapper, Section } from "@util/standard";
import React from "react";
import ContentBlock from "./PortableText";

interface Props {
  sectionData?: SanityArticleContentSection | undefined;
  imageAlignRight?: Boolean | null;
}

const ArticleSection = (props: Props) => {
  const { sectionData, imageAlignRight } = props;
  if (sectionData == null) return null;

  return (
    <>
      {imageAlignRight ? (
        <Section key={sectionData?._key} noImage={!sectionData?.sectionImage}>
          {sectionData.sectionImage && (
            <ImageWrapper>
              <SectionImg
                fluid={sectionData.sectionImage.asset.fluid}
                alt={
                  sectionData?.sectionImage.asset.title ??
                  "article-section-image"
                }
                imageStyle={{ objectFit: "contain" }}
              />
            </ImageWrapper>
          )}
          {sectionData.sectionText && (
            <TextWrapper>
              <ContentBlock blocks={sectionData?.sectionText._rawContent} />
            </TextWrapper>
          )}
        </Section>
      ) : (
        <Section key={sectionData?._key} noImage={!sectionData?.sectionImage}>
          {sectionData.sectionText && (
            <TextWrapper>
              <BlockContent blocks={sectionData?.sectionText._rawContent} />
            </TextWrapper>
          )}
          {sectionData.sectionImage && (
            <ImageWrapper>
              <SectionImg
                fluid={sectionData.sectionImage.asset.fluid}
                alt={
                  sectionData?.sectionImage.asset.title ??
                  "article-section-image"
                }
                imageStyle={{ objectFit: "contain" }}
              />
            </ImageWrapper>
          )}
        </Section>
      )}
    </>
  );
};

export default ArticleSection;
