import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { A } from '../../../util/standard'
import { colors } from '../../../util/constants'

interface Props {
  buttonText?: string;
  buttonUrl?: string;
  zIndex?: number;
}

const Button = styled(A) <{ zIndex: number}>`
text-align: center;
display:block;
font-family: 'bold';
color:white;
width: 5%;
min-width: 120px;
background-color: ${colors.green};
padding:  10px 20px;
margin: 20px auto;
${({zIndex}) => zIndex && `z-index: ${zIndex};`};

&:hover {
  background-color: ${colors.blue};
  color: ${colors.white};
}
transition: background-color 0.2s;
`

function DonateButton(props: Props) {

  const {buttonText, buttonUrl, zIndex} = props
  return (
    <Button zIndex={zIndex} href={buttonUrl ? buttonUrl : "/donate"} onClick={() => navigate(buttonUrl ? buttonUrl : '/donate')}>{buttonText ? buttonText : 'Donate'}</Button>
  )
}

export default DonateButton
